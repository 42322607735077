html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

body {
  background-color: #0a0d2e;
}

/*

TEXT

*/

@font-face {
  font-family: jakarta;
  src: url('fonts/PlusJakartaSans-Medium.woff');
  font-weight: normal;
}

@font-face {
  font-family: jakarta;
  src: url('fonts/PlusJakartaSans-Regular.woff');
  font-weight: lighter;
}

@font-face {
  font-family: jakarta;
  src: url('fonts/PlusJakartaSans-Bold.woff');
  font-weight: bold;
}

@font-face {
  font-family: jakarta;
  src: url('fonts/PlusJakartaSans-Light.woff');
  font-weight: 100;
}

p {
  font-family: jakarta;
  color: inherit;
  font-size: inherit;
}

h1 {
  font-family: jakarta;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding-left: 30px;
  padding-bottom: 60px;
}

h2 {
  font-family: jakarta;
  color:white;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 40px;
  margin-top: 12px;
}

.stock-h2 {
  font-family: jakarta;
  color:white;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 24px;
  margin-top: 12px;
}

h3 {
  font-family: jakarta;
  color: white;
  font-weight: 100;
  font-size: 2.55rem;
  margin-bottom: 36px;
}

/* stopped here */

.date-navigate-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.date-navigate {
  font-family: jakarta;
  color: rgba(255,255,255,0.4);
  font-size: 0.9rem;  
  margin-bottom: 13.5px;
}

.date-navigate:hover {
  color: #A8E0F9;

}

.about {
  font-family: jakarta;
  color: rgba(255,255,255,0.9);
  font-size: 1.125rem;
  font-weight: 100;
  line-height: 2rem;
}

.emphasis {
  font-family: jakarta;
  color: white;
  font-size: 1.125rem;
  font-weight: normal;
  text-decoration: underline;
  text-underline-offset: 4.5px;
}

.indent {
  font-family: jakarta;
  color: white;
  font-size: 1.125rem;
  opacity: 0.9;
  font-weight: lighter;
  margin-left: 45px;
  margin-right: 45px;
}

.button {
  font-family: jakarta;
  float: right;
  background:none;
  border: 1px solid #A8E0F9;
  color: #A8E0F9;
  opacity: 0.6;
  font-size: 1.35rem;
  font-weight: bold;
  width: 150px;
  height: 65px;
  border-radius: 15px;
  margin-top: 52.5px;
  text-align: center;
  padding: auto;
}

.button-active {
  opacity: 1;
  color: black !important;
  background: #A8E0F9;
  pointer-events: all !important;
  transition: ease background 0.2s;
}

.copied {
  color: white;
  font-family: jakarta;
  font-weight: bold;
  font-size: 0.9rem;
  position: absolute;
  text-align: center;
  right: 485px;
  top: 78px;
  line-height: 0.75rem;
}

.financial-subhead {
  font-family: jakarta;
  margin-top: 4.5px;
  font-size: 0.9rem;
  color: white;
  float: right;
  text-decoration: none;
  text-align: right;
  display: none;
  line-height: 1.5rem;
}

.financial-active {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.financial-active p:hover {
  color:#A8E0F9;
  transition: ease color 0.1s;
}

.financial-active a:hover {
  color:#A8E0F9;
  transition: ease color 0.1s;
}


.display {
  opacity: 1 !important;
}

.hide {
  opacity: 0 !important;
  transition: ease opacity 0.3s;
}

.header-link-text {
  font-family: jakarta;
  font-weight: normal;
  font-size: 0.975rem;
  color: white;
}

.header-link-text:hover {
  color: #A8E0F9;
}

.active {
  color: rgba(168,224,249,1) !important;
  border-bottom: 1px solid rgba(168,224,249,0.6) !important;
}

.stock {
  font-size: 1.4rem;
  color: white;
  margin-bottom: 15px;
  font-weight: bold;
}

.stock-price {
  font-size: 1.2rem;
  font-weight: normal;
  width: 180px;
}

.stock-price-percentage {
  font-size: 0.75rem;
  font-weight: normal;
  margin-top: 5px;
}

@media screen and (max-width: 700px) {

  .stock {
    font-size: 1.2rem;
  }
  .stock-price {
    font-size: 1.1rem;
  }
  .stock-price-percentage {
    font-size: 0.6rem;
  }
}

.header-link {
  margin-left: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
}

.market-day {
  font-family: jakarta;
  font-size: 0.9rem;
  margin: auto;
  color: white;
}

.market-day:active {
  color:#A8E0F9 !important;
}

.non-market-day {
  pointer-events: none;
  font-family: jakarta;
  margin: auto;
  color: white;
  opacity: 0.3;
}

/* 

CONTAINERS 

*/

.header-container {
  top: 53px;
  right: 75px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  box-sizing: border-box;
}

.main-container {
  max-width: 900px;
  margin: auto;
  padding-top: 60px;
}

.about-container {
  max-width: 750px;
  margin: auto;
  line-height: 1.8rem;
}

.stock-container1 {
  width: 100%;
  display: flex;
}

.stock-container2 {
  margin-top: 45px;
  width: 645px;
  float: left;
}

@media screen and (max-width: 700px) {
 .stock-container2 {
   max-width: 90% !important;
 }
}

.stock-header-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  float: left;
  margin-bottom: 30px;
  margin-left: -10px;
  margin-top: 40px;
}

.stock-header-container2 {
  height: 32px;
  margin-right: 12px;
  margin-left: 12px;
  font-family: jakarta;
  font-size: 1rem;
  text-align: center;
}

.stock-header-navlink {
  color: rgba(255,255,255,0.4);
  border-bottom: 1px solid rgba(255,255,255,0.3)
}

.stock-header-navlink:hover {

  color: rgba(168,224,249,1) !important;
  border-bottom: 1px solid rgba(168,224,249,0.6) !important;
  transition: ease color 0.2s, ease border-bottom 0.2s;
}

.price-volume-container {
  width: 100%;
  color: white;
  display: flex;
  margin-bottom: 0px;
}

.price-container {
  width: 180px;
  float: left;
}

.navlink {
  display: flex;
}

.ten-day-dates {
  width: 585px;
  display: flex;
}

.ten-day-date {
  width: 58.5px;
  text-align: center;
  font-family: jakarta;
  font-size: 0.675rem;
  color: white;
  opacity: 0.5;
}



.ten-day-active {
  opacity: 1 !important;
  transition: ease opacity 0.3s;
}

.stock-button-container {
  width: 60px;
  float: left;
  margin-top: 120px;
  margin-left: 30px;
}

.stock-button-active {
  opacity: 1 !important;
}

.stock-button {
  width: 77.5px;
  margin-top: 13px;
  height: 34.5px;
  float: left;
  text-align: center;
  font-size: 0.9rem;
  font-family: jakarta;
  color: white;
  padding-top: 10px;
  padding-bottom: -3.5px;
  opacity: 0.5;
  transition: ease color 0.2s;
  margin-left: -31px;
}

.article-container {
  width: 900px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 135px;
  margin-top: 30px;
}

.article {
  width: 375px;
  border-left: 4.5px solid white;
  padding-left: 15px;
  font-size: 0.75rem;
  padding-top: 7.5px;
  padding-bottom: 9px;
  margin-top: 22.5px;
  margin-right: 15px;
}

.review2 {
  width: 225px;
  float: right;
  text-align: right;
  margin-top: 60px;
}

.review3 {
  width: 675px;
  float: left;
}

.review {
  width: 600px;
  border-left: 3px solid white;
  padding-left: 15px;
  font-size: 0.75rem;
  padding-top: 7.5px;
  padding-bottom: 8.5px;
  line-height: 1.6rem;
  margin-top: 30px;
}

.review-source {
  font-family: jakarta;
  font-style: italic;
  color: white;
  font-size: 0.9rem;
}

.review-time {
  font-family: jakarta;
  color: rgba(255,255,255,0.5);
  font-size: 0.9rem;
  margin-bottom: 82px;
  margin-right: 18px;
}

.article:hover {
  background: rgba(168,224,249,0.4);
  transition: ease background 0.3s;
}

.review:hover {
  background: rgba(168,224,249,0.4);
  transition: ease background 0.3s;
  border-left: 3px solid rgba(0,0,0,0)
}

.article-day {
  color: rgba(255,255,255,0.5);
  padding-bottom: 4.5px;
}

.article-headline {
  font-weight: bold;
  font-size: 0.975rem;
  color: white;
  padding-bottom: 4.5px;
  text-transform: capitalize;
}

.review-headline {
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  padding-bottom: 4.5px;
}

.article-source-time {
  color: rgba(255,255,255,0.7);
}

.calendar-container1 {
  display: flex;
}

.calendar-container-month-main {
  border-radius: 12px;
  width: 405px;
  height: 270px;
  transition: ease background 0.3s;
}

@media screen and (max-width: 700px) {

  .calendar-container1 {
    display: none;
  }

  .calendar-container-month-main {
    width: 300px;
    margin: auto;
    height: 145px;
    padding-top: 6px;
  }
  .calendar-container-day-main {
    width: 300px !important;
    height: 290px !important;
    margin: auto !important;
    margin-top: 25px !important;
  }

  .calendar-container2-month {
    margin-top: 10px;
  }
  .calendar-container-month {
    font-size: 1.2rem !important;
    margin-top: 5px !important;
    height: 30px !important;
    border-radius: 15px !important;
    width: 60px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .calendar-container-day {
    font-size: 1.2rem !important;
    width: 30px !important;
    height: 30px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 5px !important;
  }

  .inactive {
    display: none;
  }
}

.light {
  background: rgba(168,224,249,0.3);
}

.dark {
  background: rgba(168,224,249,0.15);
}

.calendar-container2-month {
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-top: 7.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.calendar-container-month {
  width: 75px;
  height: 30px;
  margin-top: 9px;
  margin-bottom: 9px;
  padding-top: 3px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  text-align: center;
  font-family: jakarta;
  border-radius: 18px;
  font-size: 1.125rem;
}

.calendar-container-month:hover {
  background: rgba(168,224,249,0.4);
  transition: ease background 0.15s;
}

.calendar-active {
  background: rgba(168,224,249,0.4);
}

.calendar-inactive {
  opacity: 0.4 !important;
}

.calendar-container-day-main {
  border-radius: 12px;
  width: 405px;
  height: 270px;
  transition: ease background 0.3s;
}

.calendar-container2-day {
  margin-top: 15px;
  margin-bottom: 3px;
  margin-left: 7.5px;
  margin-right: 10.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.calendar-container-day {
  width: 36px;
  height: 21px;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 4.5px;
  margin-bottom: 4.5px;
  margin-left: 7.5px;
  padding-top: 3px;
  padding-bottom: 1.5px;
  margin-right: 7.5px;
  border-radius: 18px;
}

.calendar-container-day:hover {
  background: rgba(168,224,249,0.4);
}

.bumper {
  width: 100%;
  height: 10px;
}

.logo {
  width: 150px;
  height: auto;
  margin-top: 30px;
  margin-left: 31.5px;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  position: absolute;
  top: 52.5px;
  width: 100%;
}

/*

STOCKS

*/

.volumeBar {
  opacity: 0.3;
}

.volumeBar:hover {
  opacity: 0.8;
}

.time-cursor {
  font-family: jakarta;
  font-weight: normal;
  font-size: 0.56rem;
}

.inactive {
  pointer-events: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 48px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  background: rgba(168,224,249,0.2);
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 51px;

}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22.5px;
  left: -3.75px;
  top: -3.5px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 50%;
}

input:checked + .slider {
  background: rgba(168,224,249,0.2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #06003f;
}

input:checked + .slider:before {
  -webkit-transform: translateY(33px);
  -ms-transform: translateY(33px);
  transform: translateY(33px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 51px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-container {
  display: flex;
  width: 300px;
  float: left;
}

.loader-container {
  width: 750px;
  margin: auto;
  margin-top: 225px;
}

.loader {
  background-color: #A8E0F9;
  opacity: 0.8;
  height: 15px;
  border-radius: 10px;
  transition: width 1s ease-in-out;
  margin-bottom: 22.5px;
  margin-top: 24px;
}

.loader-text {
  font-family: jakarta;
  font-size: 1rem;
  color: white;
  font-weight: normal;
  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.loader-text-2 {
  font-family: jakarta;
  font-size: 1rem;
  color: white;
  font-weight: normal;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 40;
  animation-fill-mode: forwards;
}

.loader-h {
  font-family: jakarta;
  font-size: 1.5rem;
  color: white;
  font-weight: normal;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
      opacity: 0;
   }
}

.menu {
  display: none;
}

.hamburger {
  max-width: 40px;
}

.menu-expanded {
  display: none;
}

.phone-h2 {
  display: none;
}

/*
PHONE SCREEN SIZES
*/

@media screen and (max-width: 700px) {
  .navlink {
    display: none;
  }

  .logo {
    width: 180px;
    height: auto;
    margin-top: 10%;
    margin-left: 10%;
  }

  .phone-h2 {
      display: block;
      font-family: jakarta;
      color:white;
      font-weight: normal;
      font-size: 1.2rem;
      margin-bottom: 18px;
      margin-top: 40px;
  }

  .menu {
    display:block;
    position: absolute;
    opacity: 0.6;
    right: 15px;
    top: 15px;
  }

  .menu-expanded {
    background-color:rgba(0,0,0,0.85);
    border-radius: 5px;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 5%;
    text-align: right;
    line-height: 2.2rem;
  }

  .phone-menu {
    font-family: jakarta;
    font-size: 1.25rem;
    text-align: right;
    margin-right: 25px;
    color: white;
  }

  .sub {
    font-size: 1.25rem;
    color: white;
    line-height: 1rem;
  }
  
  .hidden {
    display: none;
  }
  
  .phone-active {
    display: block;
  }

  .about {
    font-size: 1rem;
    line-height: 1.7rem;
  }

  .about-container {
    max-width: 90%;
    margin-bottom: 40px;
  }

  .emphasis {
    font-size: 1rem;
    text-decoration: none;
    font-weight: bold;
  }

  .article {
    max-width: 90%;
  }

  .article-container {
    max-width: 90%;
  }

  .review2 {
    display: none;
  }

  .review3 {
    max-width: 80%;
    margin-left: 5%;
  }

  .review {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .article-headline {
    font-size: 0.95rem;
    line-height: 1.3rem;
  }

  .review-source {
    font-size: 0.8rem;
  }

  .review-time {
    margin-bottom: 60.5px;
    margin-right: 10px;
    margin-left: 0px;
  }

  .copied {
    display: none;
  }

  .copied-phone {
    color:rgba(168,224,249,1);
    font-family: jakarta;
    font-weight: lighter;
    font-style: italic;
    font-size: 1.4rem;
    margin: auto;
    top: 340px;
    line-height: 0.5rem;
    display: block;
    position: relative;
    text-align: center;
  }

  h2 {
    font-size: 1.5rem;
  }

  .indent {
    font-size: 1rem;
    line-height: 1.7rem;
    margin-right: 0px;
    margin-left: 20px;
  }

  .button {
    font-size: 1.5rem;
    display: none;
    width: 90%;
    height: 60px;
    margin: 5%;
    text-align: center;
  }

  .date-navigate {
    font-size: 0.8rem;
    margin-bottom: 18px;
  }

  .main-container {
    max-width: 90%;
  }

  .stock-header-container {
    margin-top: 8px;
  }

  .stock-header-container2 {
    font-size: 1rem;
    margin-bottom: 7px;
  }

}

.stock-button-container-phone {
  display: none;
}

@media screen and (max-width: 700px) {

  .ten-day-date {
    width: 20px;
  }
  .ten-day-dates {
    max-width: 100%;
  }
  .stock-button-container {
    display: none;
  }
  .stock-button-container-phone {
    display: flex;
    margin-top: -5px !important;
    width: 200px;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .stock-button {
    width: 50px;
    font-size: 0.8rem;
    margin-left: -10px;
  }

  .switch {
    width: 40px;
    height: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

.switch input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.slider:before {
  height: 14px;
  width: 14px;
  left: 0px;
}

input:checked + .slider {
  background: rgba(168,224,249,0.2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #06003f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22.5px);
  -ms-transform: translateX(22.5px);
  transform: translateX(22.5px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-container {
  display: flex;
  width: 200px;
  float: left;
  margin-left: 50px;
}

h3 {
  font-size: 1.4rem;
  font-weight: bold;
}

body {
  zoom: 100%;
}
}